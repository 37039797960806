<template>
  <div class="welcome" v-track-pageview="['/welcome']">
    <div class="nav">
      <a href="/" class="link-to-official" target="_blank">{{ $t('welcome_nav_link_text') }}</a>
      <span></span>
    </div>
    <div class="section">
      <div class="title-box">
        <img class="app-logo" :src="$t('banner_app_logo')" alt="App Logo">
        <h2 class="title">
          {{ $t('welcome_title_1') }}
          <span class="bold">
            {{ $t('welcome_title_2') }}
          </span>
        </h2>
      </div>
      <div class="video-box">
        <video class="video" autoplay muted controls
               :src="$t(video)">
        </video>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable max-len */

import languageMixin from '../mixin/languageMixin';

export default {
  name: 'Welcome',
  mixins: [languageMixin],
  data() {
    return {
      video: 'video',
    };
  },
  mounted() {
    // this.$gtag.event('welcome', { send_to: ['AW-706730704/gNGXCIaQtPIBENC1_9AC', 'G-TE27FYVFVR'] });
  },
};
</script>

<style scoped lang="less">
  .welcome {
    width: 100%;
    height: 100vh;
    min-height: 750px;
    .nav {
      width: 100%;
      max-width: 1448px;
      height: 60px;
      /*line-height: 60px;*/
      margin: 0 auto 40px;
      padding: 0 24px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .section {
      width: 100%;
      max-width: 1008px;
      height: calc(100% - 100px);
      margin: 0px auto;
      padding: 0px 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .title-box {
        height: 32%;
        text-align: center;
        .app-logo {
          height: 65%;
          margin-bottom: 16px;
        }
        .title {
          font-size: 48px;
          font-weight: 200;
          line-height: 64px;
          color: #1A1A1A;
          .bold {
            font-weight: 400;
          }
        }
      }
      .video-box {
        height: 63%;
        .video {
          height: 88%;
        }
      }
    }
  }
</style>
